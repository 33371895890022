<template>
  <v-data-table
    :headers="headers"
    :items="clients"
    class="elevation-1"
  ></v-data-table>
</template>

<script>
import http from "@/http-common";

export default {
  name: 'Clientlist',
  props: {
    serialNumber: {
      type: String,
      default: 'G1LQCLC017117',
    },
  },
  data: () => ({
      headers: [
        { text: 'IP', value: 'ip' },
        { text: 'MAC', value: 'macc' },
        { text: 'SSID', value: 'ssid' },
        { text: 'RSSI', value: 'rssi' },
        { text: 'Band', value: 'band' },
        { text: 'Traffic (MB)', value: 'traffic' },
        // { text: 'OS', value: 'os' },
        // { text: 'Manufacturer', value: 'manufacturer' },
        { text: 'Up', value: 'up' },
        { text: 'Down', value: 'down' },
      ],
      clients: [],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      http
        .get("/getclient_list?sn="+this.serialNumber)
        .then((response) => {
          this.clients = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
};
</script>