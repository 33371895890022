<template>
  <v-container>
    <v-row no-gutters class="mx-4">
        <v-col cols="4" md="4">
        <div>SN: {{details.serial_num}}</div>
        </v-col>
        <v-col cols="4" md="4">
        <div>MAC: {{details.mac_address}}</div>
        </v-col>
        <v-col cols="4" md="4">
        <div>MGMTIP: {{details.public_ip}}</div>
        </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
        <v-col cols="4" md="4">
        <div>Model: {{details.model}}</div>
        </v-col>
        <v-col cols="4" md="4">
        <div>Config Status : {{status}}</div>
        </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
        <div>Alias Name : {{deviceAlias}}</div>
    </v-row>
    <v-row no-gutters class="mx-4">
        <div>SSID : {{details.ssids}}</div>
    </v-row>
    </v-container>
</template>

<script>
import http from "@/http-common";

export default {
  name: 'Clientlist',
  data: () => ({

      details: [],
      status: "Synced to the latest",
  }),
  props: {
    serialNumber: {
      type: String,
      default: 'G1LQCLC017117',
    },
    deviceAlias: {
      type: String,
      default: 'devicetest',
    },
    deviceStatus: {
      type: String,
      default: 'Synced to the latest',
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      var str_separator;
      console.log("serial")
      console.log(this.serialNumber)
      http
        .get("/getdevicedetails?sn="+this.serialNumber)
        .then((response) => {
          this.details = response.data;
          str_separator = this.details.public_ip.split(":");
          this.details.public_ip = str_separator[0];
        })
        .catch((e) => {
          console.log(e);
        });
        if(this.deviceStatus=="online") this.status = "Synced to the latest";
        else this.status = "Not Synced"
    }
  }
};
</script>