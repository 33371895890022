<template>
  <v-container>
    <v-row no-gutters class="mx-4">
      <v-col cols="12" sm="6" md="4" center>
          <v-row no-gutters class="mx-4">
            <v-col cols="12" md="12">
              <v-icon  color="green" v-if="deviceStatus=='online'"> mdi-check-circle-outline </v-icon>
              <v-icon v-if="deviceStatus!='online'"> mdi-checkbox-blank-circle-outline </v-icon>
              {{deviceStatus}}
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-4">
            <v-col cols="12" md="12">
              Online Clients: {{client_count}}
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-4">
            <v-col cols="12" md="12">
              Clients With Weak Signal: {{client_count_weak}}
            </v-col>
          </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="4" center v-if="showgraph">
        <app-widget headerHide center>
          <option-chart
            center
            slot="widget-content"
            height="100px"
            width="100%"
            :chart-data="memory_usage"
          />
        </app-widget>
      </v-col>
      <v-col cols="12" sm="6" md="4" center v-if="showgraph">
        <app-widget headerHide center>
          <option-chart
            center
            slot="widget-content"
            height="100px"
            width="100%"
            :chart-data="cpu_usage"
          />
        </app-widget>
      </v-col>
    </v-row>
    </v-container>
</template>

<script>
import http from "@/http-common";
import OptionChart from '@/views/components/Dashboard/OptionChart.vue';
import AppWidget from '@/views/widget/AppWidget.vue';

export default {
  name: 'Clientlist',
  components: {
    OptionChart,
    AppWidget
  },
  data: () => ({
    memoryUsage: "73",
    cpuUsage: "0",
    memoryUsageArray: [],
    cpuUsageArray: [],
    showgraph: false,
    memory_usage: {
      title: {
          text: 'Memory Usage',
          textStyle: {
            fontSize: 14
          }
      },
      color : ['#c23531', '#2f4554'],
      series: [
        {
          label: {
              position: 'center',
              formatter: 'ab'
          },
          labelLine: {
              show: false
          },
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          type: 'pie',
          data: [73, 27]
        }
      ]

    },
    cpu_usage: {
      title: {
          text: 'CPU Usage',
          textStyle: {
            fontSize: 14
          }
      },
      color : ['#c23531', '#2f4554'],
      series: [
        {
          label: {
              position: 'center',
              formatter: 'cd'
          },
          labelLine: {
              show: false
          },
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          type: 'pie',
          data: [1, 99]
        }
      ],
      graph: {
        color : ['#00b04f', '#ffbf00']
      }
    },
    client_count: 0,
    client_count_weak: 0,
  }),
  props: {
    serialNumber: {
      type: String,
      default: 'G1LQCLC017117',
    },
    deviceAlias: {
      type: String,
      default: 'ACS',
    },
    deviceStatus: {
      type: String,
      default: 'ACS',
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      http
        .get("/getclient_list?sn="+this.serialNumber)
        .then((response) => {
          this.client_count_weak = 0;
          var i;
          for(i in response.data){
            if(parseInt(response.data[i].rssi)>=80) this.client_count_weak++;
          }
          this.client_count = response.data.length
          console.log("leghtasf" + response.data.length)
        })
        .catch((e) => {
          console.log(e);
        });
      http
        .get("/getdevicedetails?sn="+this.serialNumber)
        .then((response) => {
          this.memoryUsageArray.push(parseInt(response.data.memory_usage))
          this.memoryUsageArray.push(100-parseInt(response.data.memory_usage))
          this.memory_usage.series[0].data = this.memoryUsageArray
          this.memory_usage.series[0].label.formatter = response.data.memory_usage + "%"
          this.cpuUsageArray.push(parseInt(response.data.cpu_usage))
          this.cpuUsageArray.push(100-parseInt(response.data.cpu_usage))
          this.cpu_usage.series[0].data = this.cpuUsageArray
          this.cpu_usage.series[0].label.formatter = response.data.cpu_usage + "%"
          this.showgraph = true;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
};
</script>