<template>
  <div v-if="showgraph">
   <v-spacer></v-spacer>
    <v-btn color="primary" @click="initialize">
    Last 24 Hours
  </v-btn>
    <v-btn color="primary" @click="weekly">
    Last 7 Days
  </v-btn>
  <app-widget headerHide>
    <option-chart
      slot="widget-content"
      height="350px"
      width="100%"
      :chart-data="trafficSummary"
    />
  </app-widget>
  </div>
</template>

<script>
import http from "@/http-common";
import OptionChart from '@/views/components/Dashboard/OptionChart.vue';
import AppWidget from '@/views/widget/AppWidget.vue';

export default {
  name: 'Trafficgraph',
  components: {
    OptionChart,
    AppWidget
  },
  props: {
    serialNumber: {
      type: String,
      default: 'G1LQCLC017117',
    },
  },
  data: () => ({
    timeArray: [],
    dateArray: [],
    upArray: [],
    downArray: [],
    showgraph: false,
    trafficSummary: {
    title: {
        text: 'Traffic(MB)'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
    },
    legend: {
        data: ['Uplink', 'Downlink']
    },
    toolbox: {
        feature: {
            saveAsImage: {
              title: 'save as image'
            },
            dataZoom: {
              show: true,
              title: {zoom: 'zoom', back: 'back'}
            }
        }
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: false,
            data: ['a', 'c', 'c', 'd', 'e', 'f', 'g']
        }
    ],
    yAxis: [
        {
            type: 'value'
        }
    ],
    series: [
        {
            name: 'Downlink',
            type: 'line',
            stack: 'stack',
            areaStyle: {},
            emphasis: {
                focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310]
        },
        {
            name: 'Uplink',
            type: 'line',
            stack: 'stack',
            areaStyle: {},
            emphasis: {
                focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
        },
    ]
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      http
        .get("/getdevice24htraffic?sn="+this.serialNumber)
        .then((response) => {
          this.timeArray = [];
          this.upArray = [];
          this.downArray = [];
          var i;
          for (i in response.data){
            this.timeArray.push(response.data[i].time)
            this.upArray.push(response.data[i].tx)
            this.downArray.push(response.data[i].rx)
          }
          //this.client_count = response.data.length
          this.trafficSummary.xAxis[0].data = this.timeArray
          this.trafficSummary.series[1].data = this.upArray
          this.trafficSummary.series[0].data = this.downArray
          this.showgraph = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    weekly() {
      http
        .get("getdevice-daily-traffic?sn="+this.serialNumber+"&days=7")
        .then((response) => {
          this.timeArray = [];
          this.upArray = [];
          this.downArray = [];
          var i;
          for (i in response.data){
            this.timeArray.push(response.data[i].date)
            this.upArray.push(response.data[i].tx)
            this.downArray.push(response.data[i].rx)
          }
          //this.client_count = response.data.length
          this.trafficSummary.xAxis[0].data = this.timeArray
          this.trafficSummary.series[1].data = this.upArray
          this.trafficSummary.series[0].data = this.downArray
          this.showgraph = true;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
};
</script>