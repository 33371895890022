<template>
  <v-data-table
    :headers="headers"
    :items="radios"
    class="elevation-1"
  ></v-data-table>
</template>

<script>
import http from "@/http-common";

export default {
  name: 'Devicelogs',
  props: {
    serialNumber: {
      type: String,
      default: 'G1LQCLC017117',
    },
  },
  data: () => ({
      headers: [
        { text: 'RF Type', value: 'radioIndex' },
        { text: 'Channel', value: 'channel' },
        { text: 'Power', value: 'power' },
        { text: 'Bandwidth (MHz)', value: 'bandWidth' },
        { text: 'Channel Usage(%)', value: 'utilization' },
      ],
      radios: [
        {
          radioIndex: '2.4G',
          channel: 149,
          power: '100%',
          bandWidth: 80,
          utilization: '5%',
        },
        {
          radioIndex: '5G',
          channel: 11,
          power: '100%',
          bandWidth: 80,
          utilization: '40%',
        },
      ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      console.log(this.serialNumber)
      http
        .get("/getradioinfo?sn="+this.serialNumber)
        .then((response) => {
          this.radios = response.data;
          this.radios[0].radioIndex = '2.4G';
          this.radios[1].radioIndex = '5G';
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
};
</script>