<template>
  <v-data-table
    :headers="headers"
    :items="logs"
    class="elevation-1"
  ></v-data-table>
</template>

<script>
import http from "@/http-common";

export default {
  name: 'Devicelogs',
  props: {
    serialNumber: {
      type: String,
      default: 'G1LQCLC017117',
    },
  },
  data: () => ({
      headers: [
        { text: 'Type', value: 'type' },
        { text: 'Updated On', value: 'update_time' },
        { text: 'Content', value: 'reason' },
      ],
      logs: []
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      http
        .get("/getdevicelogs?sn="+this.serialNumber)
        .then((response) => {
          this.logs = response.data;
        })
        .catch((e) => {
          console.log(e);
          this.dataloaded = 1;
        });
    }
  }
};
</script>